import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import '../css/custom.scss'

import Header from './header'
import Footer from './footer'

const Layout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
        navigationJson {
          header {
            name
            link
          }
          footer {
            name
            link
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          defaultTitle={`Centro Clinico - Veterinario e Zootecnico - Sperimentale`}
          titleTemplate={`%s | Centro Clinico - Veterinario e Zootecnico - Sperimentale`}
        >
          <html lang="it" />
          {/* OneTrust Cookies Consent Notice start for www.ospedaleveterinario.unimi.it */}

          <script
            type="text/javascript"
            src="https://cdn.cookielaw.org/consent/8d9648f1-1630-4dce-a620-841d7c160d01/OtAutoBlock.js"
          />

          <script
            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
            data-document-language="true"
            type="text/javascript"
            charset="UTF-8"
            data-domain-script="8d9648f1-1630-4dce-a620-841d7c160d01"
          />

          <script type="text/javascript">{`function OptanonWrapper() {}`}</script>

          {/* OneTrust Cookies Consent Notice end for www.ospedaleveterinario.unimi.it */}
        </Helmet>
        <Header
          pathname={location ? location.pathname : '/'}
          siteTitle={data.site.siteMetadata.title}
          navigation={data.navigationJson.header}
        />

        <div className="main-wrapper">{children}</div>
        <Footer navigation={data.navigationJson.footer} />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
