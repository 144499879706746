import React from 'react'
import { Link } from 'gatsby'

export const INTERNAL_REGEX = new RegExp(`^/(?![/0-9])`);

const GatsbyLink = ({ to, title, literal = false, children, ...props }) => {
  const internal = INTERNAL_REGEX.test(to);
  if (!internal) {
    // Remove activeClassName prop from external links
    const { activeClassName, ...other } = props;
    return (
      <a href={to} target="_blank"  rel="noopener noreferrer" title={title} {...other}>
        <>
          {children}
        </>
      </a>
    );
  }
  
  return (
    <Link to={to} title={title} {...props}>
      <>
        {children}
      </>
    </Link>
  );
}

export default GatsbyLink;
