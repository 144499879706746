import React from 'react'
import {navigate} from 'gatsby-link'
import GatsbyLink  from './link'

const utils = require("../utility/navigation.js");

class Header extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      mobileClass : ''
    }
  }

  goTo = (e,obj,dataset) => {
    
    if (obj.url) {
     
      if (typeof(window) !== 'undefined') {
        window.location.href = obj.url;
      } else {
        var url = new URL(obj.url);
        navigate(url.pathname);
      }
    }
  }
 
  handleClick(e) {
    e.preventDefault();
    let cssClass = (this.state.mobileClass === "open") ? "" : "open";
    this.setState({
      mobileClass: cssClass
    });
  }


  render() {
    const {mobileClass} = this.state;
    const navigation = this.props.navigation;
    const pathname = this.props.pathname;

    return <header className="header" role="banner">
    {/* header middle */}
    <div className="header__middle">
      <div className="container">
        <div className="row">
          <div className="col-3 col-md-6">
            <div className="logo">
              <GatsbyLink to="/" title="CZDS | Centro Zootecnico Didattico Sperimentale" className="logo__link">
                <img  height="120" src="/images/logo-CZDS.png" alt="CZDS | Centro Zootecnico Didattico Sperimentale" className="logo__img d-print-none" />
                <span className="logo__name visuallyhidden d-print-block">CZDS | Centro Zootecnico Didattico Sperimentale</span>
              </GatsbyLink>
            </div>
          </div>
          <div className="col-6 col-md-6 d-print-none">
            <div className="logo-unimi">
              <a rel="noopener noreferrer" href="http://www.unimi.it/" title="UniMi" className="logo-unimi__link">
                <img src="/images/logo-unimi.png" alt="UniMi" className="logo-unimi__link" />
                <span className="visuallyhidden d-print-block">UniMi</span>
              </a>
            </div>
          </div>
          <div className="col-3 d-block d-md-none d-print-none">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div id="toggle-menu-btn" className={"hamburger "+mobileClass} onClick={(e) => this.handleClick(e)}>
              <span>&#160;</span>
              <span>&#160;</span>
              <span>&#160;</span>
              <span>&#160;</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/*  header bottom */}
    <div className={"header__bottom "+mobileClass}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="hb-wrapper clearfix">
             
              {!!navigation &&
                <nav id="nav" className="navigation hidden-print" itemType="http://schema.org/SiteNavigationElement" itemScope="itemscope" role="navigation">
                  {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role */}
                  <ul className="navigation__list" role="menu" aria-orientation="horizontal">
                    {navigation.map((item, index) => {
                      let isfirst = index===0?"navigation__item--first ":"";
                      let haschildren = !!item.children?"navigation__item--has_sub ":"";
                      let isActive = utils.isActive(pathname,item);
                      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                      return <li key={index} className={"navigation__item "+isfirst+haschildren+isActive} role="menuitem" itemProp="name">
                        <GatsbyLink to={item.redirect || item.link} title={item.name} className="navigation__link" itemProp="url">{item.name}</GatsbyLink>
                      </li>
                    })}
                  </ul>
                </nav>
              }
              
              <div className="area-riservata">
                <a rel="noopener noreferrer" href="https://users.unimi.it/unimibox/index.php/login" target="_blank" title="" className="area-riservata__link">Area riservata</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  }

}

export default Header